<template>
    <div class="row mx-0 bg-white br-8 p-3">
        <p class="col-12 px-0 text-general mb-3 f-600 f-18">
            Misiones
        </p>
        <div class="col-12 px-0 scroll-none overflow-auto pb-4">
            <div class="mt-2 a-center pl-2" style="display:inline-flex;">
                <div class="card-misiones mr-3" @click="$router.push({name: 'recompensas.misiones.mes'})">
                    <p class="f-12 text-center text-gris2 my-2">
                        Septiembre
                    </p>
                    <div class="row mx-0 mt-2 j-center">
                        <div class="circled-wrap">
                            <div class="inside">
                                <img src="/img/ilustraciones/gaming/dinero.png" height="80  " width="80"  />
                            </div>
                            <div class="checked">
                                <i class="icon-ok-circled-outline f-20 text-green" />
                            </div>
                        </div>
                    </div>
                    <p class="text-center text-gris2 f-12 f-500 mt-1">
                        Compras
                    </p>
                    <p class="text-center text-gris2 f-12 f-600 mt-1">
                        $ 51.000 / $ 100.000
                    </p>
                    <div class="row mx-0 j-center my-2">
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/ilustraciones/gaming/i_moneda.svg" height="20" />
                            <span class="mx-2">2</span>
                        </div>
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/ilustraciones/gaming/i_gema.svg" height="20" />
                            <span class="mx-2">1</span>
                        </div>
                    </div>
                </div>
                <div class="card-misiones mr-3">
                    <p class="f-12 text-center text-gris2 my-2">
                        Septiembre
                    </p>
                    <div class="row mx-0 mt-2 j-center">
                        <div class="circled-wrap">
                            <div class="inside">
                                <img src="/img/ilustraciones/gaming/dinero.png" height="80  " width="80"  />
                            </div>
                            <div class="checked">
                                <i class="icon-ok-circled-outline f-20 text-green" />
                            </div>
                        </div>
                    </div>
                    <p class="text-center text-gris2 f-12 f-500 mt-1">
                        Compras
                    </p>
                    <p class="text-center text-gris2 f-12 f-600 mt-1">
                        $ 51.000 / $ 100.000
                    </p>
                    <div class="row mx-0 j-center my-2">
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/ilustraciones/gaming/i_moneda.svg" height="20" />
                            <span class="mx-2">2</span>
                        </div>
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/ilustraciones/gaming/i_gema.svg" height="20" />
                            <span class="mx-2">1</span>
                        </div>
                    </div>
                </div>
                <div class="card-misiones mr-3" @click="$router.push({name: 'recompensas.misiones.evento'})">
                    <p class="f-12 text-center text-gris2 my-2">
                        Evento
                    </p>
                    <div class="row mx-0 mt-2 j-center">
                        <div class="circled-wrap">
                            <div class="inside">
                                <img src="/img/ilustraciones/gaming/dinero.png" height="80  " width="80"  />
                            </div>
                            <div class="checked">
                                <i class="icon-ok-circled-outline f-20 text-green" />
                            </div>
                        </div>
                    </div>
                    <p class="text-center text-gris2 f-12 f-500 mt-1">
                        Compras
                    </p>
                    <p class="text-center text-gris2 f-12 f-600 mt-1">
                        $ 51.000 / $ 100.000
                    </p>
                    <div class="row mx-0 j-center my-2">
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/ilustraciones/gaming/i_moneda.svg" height="20" />
                            <span class="mx-2">2</span>
                        </div>
                        <div class="mn-pill bg-fondo text-gris2 f-600">
                            <img src="/img/ilustraciones/gaming/i_gema.svg" height="20" />
                            <span class="mx-2">1</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.card-misiones{
    width: 148px;
    height: 199px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    .circled-wrap{
        width: 80px;
        height: 80px;
        background: #FFFFFF;
        padding: 5px;
        position: relative;
        border-radius: 50%;
        border: 5px solid #DFE4E8;
        .inside{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #E9ECEF;
            border: 8px solid #DFE4E8;
        }
        .checked{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            position: absolute;
            bottom: 10px;
            right: -7px;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            box-shadow: 0px 3px 6px #00000014;
        }
    }
}
</style>